<div
  #toTopBtn
  class="prgs psu-bfr psu-aft"
  [ngClass]="[showButton]"
  (click)="scrollToTop()"
  title="back to top"
>
  <svg viewBox="-1 -1 102 102" role="presentation">
    <path
      #path
      d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"
      style="stroke-dasharray: 307.919"
    ></path>
  </svg>
</div>
