<div class="bx bg-bx-drk vp:lg psu-bfr rel">
  <div class="mn">
    <div class="con nlf-middle-between">
      <!-- Logo -->
      <app-logo class="third"></app-logo>

      <!-- Tagline -->
      <app-copyright
        class="third"
        textTop="Built in Angular with ❤️"
        textBottom="© 2020 Oscar Saca"
      ></app-copyright>

      <!-- Social Links -->
      <div class="third nlf-middle-end ctr-1024">
        <app-social-link
          [link]="link"
          *ngFor="let link of socialLinks"
        ></app-social-link>
      </div>
    </div>
  </div>
</div>
