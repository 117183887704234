<a
  class="nlf-middle-center rel"
  rel="nofollow noopener"
  [attr.id]="link.uniqueID"
  [attr.href]="link.href"
  [attr.title]="link.dataTip"
  [attr.data-tip]="link.dataTip"
  [attr.target]="link.target"
  ><svg viewBox="0 0 36 36"><path [attr.d]="link.svgPath" /></svg
></a>
