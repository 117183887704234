<div class="mn">
  <!-- Header -->
  <app-section-header
    class="mb:"
    title="Skills & Technologies"
  ></app-section-header>

  <!-- Chart -->
  <app-chart></app-chart>
</div>
