<!-- Header -->
<header>
  <app-masthead></app-masthead>
</header>
<!-- Body -->
<main @fadeIn>
  <section>
    <app-mainstage></app-mainstage>
  </section>
  <section>
    <app-skills></app-skills>
  </section>
  <section>
    <app-about></app-about>
  </section>
</main>
<!-- Footer -->
<footer>
  <app-footer></app-footer>
  <app-back-to-top></app-back-to-top>
</footer>
