<picture class="img hdn" role="presentation">
  <source
    media="(max-width: 500px)"
    srcset="/assets/images/grid-lines-img-mobile.webp"
  />
  <source
    media="(max-width: 800px)"
    srcset="/assets/images/grid-lines-img-tablet.webp"
  />
  <source
    media="(max-width: 1024px)"
    srcset="/assets/images/grid-lines-img-small-laptop.webp"
  />
  <source
    media="(max-width: 1200px)"
    srcset="/assets/images/grid-lines-img-laptop.webp"
  />
  <img src="/assets/images/grid-lines-img.webp" alt="" loading="lazy" />
</picture>
