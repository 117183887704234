<!-- Logo -->
<app-logo [animate]="true"></app-logo>

<!-- Skip To Content -->
<app-skip-to-content></app-skip-to-content>

<!-- Navigation -->
<nav class="nlf-middle usr-scl" role="navigation" aria-label="Main">
  <app-navigation-link
    class="hd-800"
    [link]="link"
    *ngFor="let link of links"
  ></app-navigation-link>
</nav>
