<ng-container *ngIf="!link?.download">
  <a class="link ttl-2" [attr.href]="link.href" [attr.title]="link.name">{{
    link.name
  }}</a>
</ng-container>
<ng-container *ngIf="link?.download">
  <a class="btn has-icon" [attr.href]="link.href" title="download resume" download>
    <span>{{ link.name }}</span>
    <svg viewBox="0 -0.5 17 17" xmlns="http://www.w3.org/2000/svg" style="max-width:17px;max-height:17px" role="presentation">
        <g transform="translate(1.000000, 1.000000)" fill="#ffffff">
          <path d="M14.031,8.016 L14.031,12.016 L2,12.016 L2,8.016 L0,8.016 L0,15 L15.938,15 L15.938,8.016 L14.031,8.016 Z"></path>
          <path d="M8.072,8.947 L10.982,5.071 L8.968,5.05 L8.968,0.065 L7.03,0.065 L7.03,5.05 L5.03,5.05 L8.072,8.947 Z"></path>
        </g>
    </svg>
  </a>
</ng-container>

