<!-- Animating Lines -->
<app-film-lines role="presentation"></app-film-lines>

<!-- Background Planets -->
<app-background-planets role="presentation"></app-background-planets>

<!-- Info/Content -->
<app-info></app-info>

<!-- Mountains -->
<app-mountains role="presentation"></app-mountains>
